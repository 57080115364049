@import url("./select.css");

body {
  margin: 0;
  padding: 0;
}

.login-signup-bg {
  background-color: #234323;
  width: 100%;
  min-height: 100vh;
}

.form-wrapper {
  padding: 20px;
  opacity: 0.75;
  border-radius: 20px;
  box-sizing: border-box;
}

.login-form-wrapper {
  background-color: #0b2948;
  padding: 15px 10px;
  border-radius: 20px;
}

.login-form-wrapper form label,
.login-form-wrapper h4,
.login-form-wrapper span {
  color: #8ba1b7 !important;
}

.form-dark input,
.login-form-wrapper form input {
  border-radius: 10px;
  padding-left: 5px;
}
.form-dark label {
  color: #8ba1b7 !important;
}

.form-dark div {
  color: #798c9f !important;
}

.modal-dark .modal-dialog .modal-content {
  background: #0b2948 !important;
}

.signup-btn {
  padding: 8px 16px;
  border-radius: 20px;
  background-color: #0867c6;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: 600;
}

.text-field {
  width: 100%;
  padding: 8px 10px;
  border: none;
  border-bottom: 1px solid #333;
  outline: none;
  background-color: #030039;
  color: #bcbaba;
}

.header-profile {
  /* background-image: url(../public/images/profile.png); */
  background-size: cover;
}

.profile-menu {
  border-radius: 10px;
  background-color: #c5bcbc;
  color: #14225f;
  flex-direction: column;
  top: 54px;
  right: 0;
  width: 155px;
  padding: 5px;
  border-top: 3px solid #0867c6;
  z-index: 10;
}

.menu-btn {
  padding: 8px 5px;
  border-radius: 10px;
  display: block;
}

.menu-btn:hover {
  background-color: #f7cece;
}

.css-s2j907.ps-collapsed {
  width: 100px !important;
}
.upload {
  height: 73px;
  width: 464px;
  border-radius: 12px;
  background: #b1adad;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.search-input {
  width: 98%;
  height: 100%;
  margin-right: 8px;
  border: 1px solid grey;
  border-radius: 6px;
  outline: none;
}
::-webkit-scrollbar {
  width: 1px;
}
.card-item {
  border: 0px solid black;
  height: 130px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  color: white;
  border-radius: 5%;
}

.action-cell {
  padding: 0px !important;
  border: 0px !important;
  color: #1d4973;
  text-overflow: initial;
}

p.MuiTablePagination-displayedRows,
p.MuiTablePagination-selectLabel {
  margin: 0px !important;
}

li.ps-active {
  background-color: #0867c6;
  color: #fff;
}

.text-label {
  display: block;
  color: #1346a3;
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper input {
  border: 0px;
  width: 100%;
  border-bottom: 1px solid #333;
  outline: none;
  padding: 8px 5px;
}

.dark-sort-label svg {
  color: inherit !important;
}

.dark-pagination svg {
  color: #8ba1b7 !important;
}

.css-10cbwbc-MuiTableRow-root.MuiTableRow-hover.dark:hover {
  background-color: #1a1845a1 !important;
}

.filter-container {
  width: 230px;
  height: 250px;
  background: #ccc;
  top: 31px;
  right: 46px;
  border-radius: 10px;
  padding: 5px;
  border: 1px solid #eee;
}

.filter-container.dark {
  background-color: #1346a3 !important;
}

.filter-container .form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.filter-container .form-group label {
  position: relative;
  cursor: pointer;
}

.filter-container .form-group label:before {
  content: "";
  background-color: transparent;
  border: 2px solid #0079bf;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}

.filter-container .form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 5px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.filter-container .clear-filter-btn {
  border: none;
  outline: none;
  background-color: transparent;
  border: 1px solid #8ba1b7;
  border-radius: 6px;
  color: inherit;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.reset-logo {
  right: 0;
  top: 0;
  background: no-repeat;
  border: none;
  color: #eee;
}

.generate-qr-btn {
  background: transparent;
  border: 1px solid #eee;
  border-radius: 5px;
  padding: 5px;
}

/* react-date-picker custom styling */
select.react-datepicker__month-select,
select.react-datepicker__year-select {
  padding: 6px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* checkbox styling */
.check-box-container span {
  padding: 0;
}

.check-box-container.dark span, .check-box-container.dark label {
  color: #8ba1b7;
}

.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root{
  color: #8ba1b7 !important;
}

button.signup-btn.disabled {
  background-color: #0867c678;
  color: #908888;
  cursor: default;
}

.react-confirm-alert-overlay {
  background: rgb(80 85 105 / 90%) !important;
}

.custom-ui {
  padding: 10px;
  border-radius: 20px;
}

.custom-ui button {
  background: #9fe7e7b8;
  padding: 4px 15px;
  border: none;
  border-radius: 8px;
  margin-left: 10px;
}

.custom-ui .no-btn {
  background: #a6d3d3b8 !important;
}

.card-container {
  background-color: #798c9f;
  width: 300px;
  height: 250px;
  border-radius: 10px;
}

.month-filter {
  width: 250px;
}

.month-filter .MuiInputBase-input {
  border: 1px solid #8ba1b7;
  color: #8ba1b7;
}

.month-filter svg {
  color: #8ba1b7;
}

.time-picker.dark label,
.time-picker.dark input,
.time-picker.dark svg {
  color: #8ba1b7;
}

.time-picker.dark fieldset {
  border: 1px solid #8ba1b7;
}

.SearchSelect-dark label,
.SearchSelect-dark input,
.SearchSelect-dark .MuiAutocomplete-popupIndicator {
  color: #8ba1b7;
}

.SearchSelect-dark {
  border: 0;
}

.css-2m9kme-MuiFormControl-root{
  margin-top: 25px !important;
  margin-left: 0px !important;
  width: 100% !important;
}

/* campaign-page */

.campaign-popup {
  position: absolute;
  top: 35px;
  right: 20px;
  min-width: 180px;
  background-color: #111045;
  border-radius: 8px;
  color: #f5f5f5;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.6);
  padding: 10px;
  transform-origin: top right;
  animation: scalePopup 0.3s ease forwards;
}

.campaign-item {
  width: 400px;
  min-height: 200px;
  margin: 10px 10px 10px 0px;
  border-radius: 10px;
  box-shadow: 5px 5px 5px rgba(0,0,0, 0.5);
  background-color: rgb(9 61 114);
  padding: 10px;
}

.indicator {
  position: absolute;
  top: -9px;
  right: -5px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.ongoing{
  background-color: green;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.popup-item{
  margin-bottom: 8px;
}

.popup-item:hover{
  border-bottom: 1px solid #ccc;
}

@keyframes scalePopup {
  0% {
    opacity: 0;
    transform: scale(0.8);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


ul.MuiPagination-ul.css-wjh20t-MuiPagination-ul li button {
  color: #0079bf !important;
  font-size: 24px;
}

.drop-zone {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  border: 2px dashed #ccc;
  border-radius: 10px;
  text-align: center;
  font-size: 16px;
  color: #666;
  position: relative;
  cursor: pointer;
}

.drop-zone.active {
  border-color: #6c63ff;
  background-color: #f9f9f9;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

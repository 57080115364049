 select::-ms-expand {
    display: none;
 }

 .filter-container .select-component {
    padding: 10px 5px;
    border: 1px solid #ccc;
    background-color: transparent;
    border-radius: 8px;
    outline: none;
    position: relative;
    display: flex;
    overflow: hidden;
  }

 .filter-container .select-component ::after {
    content: '\25BC';
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 1em;
    background: #2b2e2e;
    cursor:pointer;
    pointer-events:none;
    transition:.25s all ease;
 }
 .filter-container .select-component:hover::after {
    color: #23b499;
 }